/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
"use strict";

AOS.init({
    once: true
});

// Burger menu 

let currentPage = document.querySelector(".menu__burger");

if (currentPage != null) {
    let navButton = document.querySelector(".menu__burger");
    navButton.addEventListener("click", toggleNavigation);

    function toggleNavigation() {
        if (document.body.hasAttribute("data-menu")) {
            document.body.removeAttribute("data-menu");

        } else {
            document.body.setAttribute("data-menu", true);
        }
    }

}

/* //parallax

let para = document.querySelectorAll(".work__prj__img");

for (var i = 0; i < para.length; i++) {

    let tempVar = para[i];

    window.addEventListener('scroll', () => {
        tempVar.style.transform = "translateY" + window.scrollY / 3 + "px";

    })
} */
